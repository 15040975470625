import Device from '../../hooks/useDevice'

let scheme = false
if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  scheme = true
}
if (window.matchMedia('(prefers-color-scheme: light)').matches) {
  scheme = false
}

const device = Device()

const initialState = {
  domains: {
    LEiuag3CoBOWXBVBgamO: {
      domainBackground: '#ffffff',
      domainColor: '#4285F4',
      domainHostname: 'google.com',
      domainProtocol: 'https://',
      domainWWW: true,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/LEiuag3CoBOWXBVBgamO.svg',
      verifiedD: true,
    },
    v3WoruOWGrTsBkXN2Rka: {
      domainBackground: '#FFFFFF',
      domainColor: '#4285f4',
      domainHostname: 'mail.google.com',
      domainProtocol: 'https://',
      domainWWW: false,
      domainTitle: 'Gmail',
      domainPicture:
        'https://bucket.titletab.com/domains/v3WoruOWGrTsBkXN2Rka.svg',
      verifiedD: true,
    },
    '3vwHoVPYQugKyoro1w5A': {
      domainBackground: '#d00000',
      domainColor: '#ffffff',
      domainHostname: 'youtube.com',
      domainProtocol: 'https://',
      domainWWW: true,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/3vwHoVPYQugKyoro1w5A.svg',
      verifiedD: true,
    },
    KbCRbAsQh65fr6AG0i6s: {
      domainBackground: '#000000',
      domainColor: '#da0707',
      domainHostname: 'netflix.com',
      domainProtocol: 'https://',
      domainWWW: true,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/KbCRbAsQh65fr6AG0i6s.svg',
      verifiedD: true,
    },
    ekPc1sqNvzuxVUenijGQ: {
      domainBackground: '#ffffff',
      domainColor: '#de5833',
      domainHostname: 'duckduckgo.com',
      domainProtocol: 'https://',
      domainWWW: false,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/ekPc1sqNvzuxVUenijGQ.svg',
      verifiedD: true,
    },
    ja2ANxPpTTXQDflX4SEu: {
      domainBackground: '#3b5997',
      domainColor: '#ffffff',
      domainHostname: 'facebook.com',
      domainProtocol: 'https://',
      domainWWW: true,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/ja2ANxPpTTXQDflX4SEu.svg',
      verifiedD: true,
    },
    qg2N88mn4abcgG0GYSqL: {
      domainBackground: '#1da1f3',
      domainColor: '#FFFFFF',
      domainHostname: 'twitter.com',
      domainProtocol: 'https://',
      domainWWW: false,
      domainTitle: '',
      domainPicture:
        'https://bucket.titletab.com/domains/qg2N88mn4abcgG0GYSqL.svg',
      verifiedD: true,
    },
  },
  pages: {
    waqE80psvfuV32hfo60m: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'google.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/waqE80psvfuV32hfo60m.webp',
      pagesTitle: 'Google',
      verifiedP: true,
    },
    MhioSt3r1oaBb61sqNCc: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'mail.google.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/MhioSt3r1oaBb61sqNCc.webp',
      pagesTitle: 'Gmail',
      verifiedP: true,
    },
    XXgER6U2udQYo11n3Z7S: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'youtube.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/XXgER6U2udQYo11n3Z7S.webp',
      pagesTitle: 'YouTube',
      verifiedP: true,
    },
    XkqcOUA25yzKVNJvKhEC: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'netflix.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/XkqcOUA25yzKVNJvKhEC.webp',
      pagesTitle: 'Netflix',
      verifiedP: true,
    },
    uO5coS4pySchcoycr1CB: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'duckduckgo.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/uO5coS4pySchcoycr1CB.webp',
      pagesTitle: 'DuckDuckGo',
      verifiedP: true,
    },
    '3VyW22KfearRCHmcR4S3': {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'facebook.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/3VyW22KfearRCHmcR4S3.webp',
      pagesTitle: 'Facebook',
      verifiedP: true,
    },
    EaCKGDaJDYv9iFdokvz8: {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'twitter.com',
      pagesPathname: '/',
      pagesPicture:
        'https://bucket.titletab.com/pages/EaCKGDaJDYv9iFdokvz8.webp',
      pagesTitle: 'Twitter',
      verifiedP: true,
    },
    '2SIL6gf47wqKXFFsR8pA': {
      influent: false,
      pagesBackground: '',
      pagesColor: '',
      pagesHostname: 'youtube.com',
      pagesPathname: '/watch?v=5ekBSCIG1mw',
      pagesPicture: '',
      pagesTitle: 'Experience with TitleTab.com',
      verifiedP: false,
    },
  },
  spaces: {
    1: {
      space: 'Main Space',
      icon: 'bx bx-bookmarks',
      tasks: {
        1: {
          id: '1',
          idDomain: 'LEiuag3CoBOWXBVBgamO',
          idPages: 'waqE80psvfuV32hfo60m',
          idParent: null,
          ownBackground: '',
          ownColor: '',
          ownTitle: '',
          ownPicture: '',
          pictureImage: null,
          tabFill: '',
        },
        2: {
          id: '2',
          idDomain: 'v3WoruOWGrTsBkXN2Rka',
          idPages: 'MhioSt3r1oaBb61sqNCc',
          idParent: null,
          ownBackground: '',
          ownColor: '',
          ownTitle: '',
          ownPicture: '',
          pictureImage: null,
          tabFill: '',
        },
        3: {
          id: '3',
          idDomain: '3vwHoVPYQugKyoro1w5A',
          idPages: 'XXgER6U2udQYo11n3Z7S',
          idParent: null,
          ownBackground: '',
          ownColor: '',
          ownTitle: '',
          ownPicture: '',
          pictureImage: null,
          tabFill: '',
        },
        4: {
          id: '4',
          idDomain: 'KbCRbAsQh65fr6AG0i6s',
          idPages: 'XkqcOUA25yzKVNJvKhEC',
          idParent: null,
          ownBackground: '',
          ownColor: '',
          ownTitle: '',
          ownPicture: '',
          pictureImage: null,
          tabFill: '',
        },
        5: {
          id: '5',
          idDomain: 'ekPc1sqNvzuxVUenijGQ',
          idPages: 'uO5coS4pySchcoycr1CB',
          idParent: null,
          ownBackground: '',
          ownColor: '',
          ownTitle: '',
          ownPicture: '',
          pictureImage: null,
          tabFill: '',
        },
        6: {
          id: '6',
          tasks: {
            1: {
              id: '1',
              idDomain: 'ja2ANxPpTTXQDflX4SEu',
              idPages: '3VyW22KfearRCHmcR4S3',
              idParent: null,
              ownBackground: '',
              ownColor: '',
              ownTitle: '',
              ownPicture: '',
              pictureImage: null,
              tabFill: '',
            },

            2: {
              id: '2',
              idDomain: 'qg2N88mn4abcgG0GYSqL',
              idPages: 'EaCKGDaJDYv9iFdokvz8',
              idParent: null,
              ownBackground: '',
              ownColor: '',
              ownTitle: '',
              ownPicture: '',
              pictureImage: null,
              tabFill: '',
            },
          },
          ownBackground: '',
          ownTitle: 'Social Networks',
        },
        7: {
          id: '7',
          idDomain: '3vwHoVPYQugKyoro1w5A',
          idPages: '2SIL6gf47wqKXFFsR8pA',
          idParent: null,
          ownBackground: '#0364c1',
          ownColor: '#ffffff',
          ownTitle: 'How it works? On YouTube',
          ownPicture:
            'https://bucket.titletab.com/images/1676454189887_rsah.webp',
          pictureImage: null,
          tabFill: '',
        },
      },
    },
  },
  settings: {
    defaultSearch: 'Google',
    showSearch: true,
    styleGlobalBackground: '#e5eef5',
    styleGlobalImage: '',
    styleTabsTitleColor: '#757575',
    styleTheme: scheme,
    tabsAsImage: true,
    tabsInRow: device === 'desktop' ? 5 : 2,
    tabsSizeDesktop: 5,
    tabsSizeMobile: 2,
    targetSearch: true,
    targetTabs: true,
    time: new Date().getTime(),
  },
  images: {},
  time: new Date().getTime(),
}

export default initialState
