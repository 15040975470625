import React, { useState, useEffect, useRef, useCallback } from 'react'
// import localForage from 'localforage'
import { database } from '../firebase'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { usePage } from '../context/PageContext'

import styled from 'styled-components'

import SyncData from '../function/SyncData'
import SyncSettings from '../function/SyncSettings'
import darkTheme from '../theme/darkTheme'
import lightTheme from '../theme/lightTheme'

import TrackingData from '../function/TrackingData'
import TrackingSettings from '../function/TrackingSettings'
import { ToastContainer } from 'react-toastify'

import Router from '../Router'
import Loader from '../loader/Loader'
import initialRoutes from './initialState/initialRoutes'
import Drag from './tabs/Drag'
import withLocalForage from '../context/withLocalForage'
import SetAnalytics from '../analytics/SetAnalytics'
import GetAnalytics from '../analytics/GetAnalytics'

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const State = ({
  device,
  lang,
  assignSpace,
  assignForage,
  assignGlobal,
  fromFirebase,
  forNewUser,
}) => {
  // localStorage.clear()
  // localForage.clear()

  const {
    global,
    outPublic,
    loadSpace,
    load,
    space,
    setSpaceUpdate,
    setLang,
    setDevice,
    refresh,
    launch,
    setLaunch,
    background,
  } = useData()

  const {
    setPublicPage,
    setBackgroundModal,
    analytics,
    setAnalytics,
    setAbout,
    setSignIn,
    setSignUp,
    setForgotPassword,
    setPrivacyPolicy,
    setUpdateProfile,
    setContactUs,
    setChangeEmail,
    setChangePassword,
    setDeleteAccount,
    setMobileMenu,
    setModalLarge,
    setModalSmall,
  } = usePage()

  const { currentUser } = useAuth()
  const path = `state${currentUser ? currentUser.uid : ''}`
  const uid = currentUser ? currentUser.uid : null

  // let uid = currentUser ? "2G4jolrEEYSTNO83nhns8IIjKrI3" : null;
  //add to firebase rules to data and settings :
  // allow read: if request.auth.uid == '9T4WSX6BMDXvr2V3PP3rJr3abVo1';

  const [firstLoad, setFirstLoad] = useState(true)
  // const [publicRoute, setPublicRoute] = useState(null)

  useEffect(() => {
    if (!load) return
    // if (!spaceUpdate) {
    //   setSpaceUpdate(true)
    //   return
    // }

    let timeout = 10
    if (!firstLoad) timeout = 250
    setFirstLoad(false)

    const dragContainer = document.getElementById('dragContainer')
    // if (dragContainer) dragContainer.style.opacity = 0
    setSpaceUpdate(true)
    setTimeout(() => {
      const images = document.querySelectorAll('.imgLoad')
      if (!images) return
      const handleLoad = () => {
        if (dragContainer) {
          dragContainer.style.opacity = 1
        }
      }
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve()
          } else {
            img.addEventListener('load', resolve)
          }
        })
      })
      Promise.all(promises)
        .then(handleLoad)
        .catch((dragContainer.style.opacity = 1))
    }, timeout)
  }, [load, space])

  useEffect(() => {
    setDevice(device)
    setLang(lang)
  }, [])

  const routes = initialRoutes
  const pathname = window.location.pathname
  useEffect(() => {
    const route = routes[pathname] || {}
    document.title = route.title
    setPublicPage(route.setPublicPage)
    setSignIn(route.setSignIn)
    setSignUp(route.setSignUp)
    setForgotPassword(route.setForgotPassword)
    setPrivacyPolicy(route.setPrivacyPolicy)
    setBackgroundModal(route.setBackgroundModal)
    setAnalytics(route.setAnalytics)
    setAbout(route.setAbout)
    setUpdateProfile(route.setUpdateProfile)
    setContactUs(route.setContactUs)
    setChangeEmail(route.setChangeEmail)
    setChangePassword(route.setChangePassword)
    setDeleteAccount(route.setDeleteAccount)
    setMobileMenu(route.setMobileMenu)
    setModalLarge(route.setModalLarge)
    setModalSmall(route.setModalSmall)
  }, [pathname])

  const timeout = useRef(null)

  useEffect(() => {
    if (launch && currentUser) {
      clearTimeout(timeout.current)
      updateDatabase()
    }
  }, [launch])

  async function updateDatabase() {
    timeout.current = setTimeout(() => {
      SyncData({ path, uid, device, space, fromFirebase, assignGlobal })
      SyncSettings({ path, uid, device, space, fromFirebase, assignGlobal })
    }, 2000)
  }

  useEffect(() => {
    if (currentUser) {
      database.data.doc(uid).onSnapshot((doc) => {
        let base = doc.data()
        TrackingData({ base, path, launch, setLaunch })
      })

      database.settings.doc(uid).onSnapshot((doc) => {
        let base = doc.data()
        TrackingSettings({ base, path, launch, setLaunch })
      })
    }
  }, [])

  useEffect(() => {
    getState()
  }, [refresh, pathname])

  async function getState() {
    let pathname = window.location.pathname

    if (routes[pathname] && global && space) {
      //чтобы не мигало при переходе по ссылкам служебных страниц
      return
    }

    if (routes[pathname]) {
      //чтобы не искал данные по ссылкам служебных страниц
      pathname = null
    }

    const space2 = await assignSpace()
    let forage = await assignForage(path)
    if (forage) {
      return assignGlobal(forage, space2, uid, pathname)
    }
    if (uid) {
      const firebase = await fromFirebase(path, uid, device)
      return assignGlobal(firebase, space2, uid, pathname)
    }
    if (!uid) {
      const newUser = await forNewUser(path, device)
      return assignGlobal(newUser, space2, uid, pathname)
    }
  }

  const dragData = useCallback(() => {
    if (!global) return
    if (analytics) return <GetAnalytics />
    if (outPublic)
      return (
        <Drag
          dragData={outPublic}
          main={true}
          id={'dragContainer'}
          type={'outPublic'}
        />
      )
    if (space != null && !loadSpace)
      return (
        <Drag
          dragData={global.spaces[space]}
          main={true}
          id={'dragContainer'}
          type={'space'}
        />
      )
  })

  let elem = document.querySelector('body')
  if (elem) {
    let path = `background${currentUser ? currentUser.uid : ''}`

    let background = localStorage.getItem(path) || null

    if (background) {
      let img = new Image()
      img.src = background

      img.addEventListener('load', function () {
        document.createElement('img').src = background
        elem.style.transition = 'none'
        elem.style.backgroundImage = `url('${background}')`
        elem.style.backgroundSize = 'cover'
        elem.style.backgroundPosition = 'center'
      })
    } else {
      let isDarkMode = global
        ? global.settings.styleTheme
        : window.matchMedia('(prefers-color-scheme: dark)').matches

      if (isDarkMode) {
        elem.style.backgroundColor = '#2a2545'
        elem.style.backgroundImage = darkTheme
        setTimeout(() => {
          elem.style.transition = 'all 0.5s ease'
        }, 200)
        elem.style.backgroundSize = null
        elem.style.backgroundPosition = null
      } else {
        elem.style.backgroundColor = '#D6E7F5'
        elem.style.backgroundImage = lightTheme
        setTimeout(() => {
          elem.style.transition = 'all 0.5s ease'
        }, 200)
        elem.style.backgroundSize = null
        elem.style.backgroundPosition = null
      }
    }
  }

  return (
    <Container>
      <ToastContainer
        draggablePercent={20}
        limit={5}
        position={device === 'desktop' ? undefined : 'bottom-right'}
      />
      {dragData()}
      {global ? <Router /> : null}
      <Loader global={global} loadSpace={loadSpace} />
      <SetAnalytics uid={uid} />
    </Container>
  )
}

export default withLocalForage(State)
