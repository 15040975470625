import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  width: ${(props) => props.styleData.width}px;
  top: ${(props) => props.styleData.top}px;
  left: 0px;
  right: 0px;
  margin: auto;

  @media (max-width: 1200px) {
    top: 85px;
    left: auto;
    right: 0px;
  }
`

export const TodayContainer = styled.div`
  width: calc(66.6% - 20px);
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  hr {
    width: 30%;
    height: 1px;
    background-color: #fff;
    border: none;
    margin: 10px 0px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px 40px;
  }

  .visitors,
  .views,
  .clicks {
    width: 33.3%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }

  .number {
    font-size: 60px;
    font-weight: 600;
    color: #fff;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
`

export const ExtantionsContainer = styled.div`
  width: calc(33.3% - 20px);
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px;
  }
`

export const MonthGraphContainer = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  margin: 10px;
  min-height: 250px;
  background-color: #00000020;
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 275px;
    margin: 20px;
`
