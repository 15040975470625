import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { ExtantionsContainer } from '../styles/GetAnalytics.styled'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function Extantions({ state }) {
  const { useRegistration, notRegistration } = state

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const data = {
    labels: ['Registered User', 'Not Registered User'],
    datasets: [
      {
        label: 'Users',
        data: [useRegistration, notRegistration],
        backgroundColor: ['rgba(26, 115, 232, 1)', 'rgba(217, 48, 37, 1)'],
        borderColor: ['rgba(26, 115, 232, 1)', 'rgba(220, 78, 65, 1)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <ExtantionsContainer>
      <div className="content">
        <Pie data={data} options={options} />
      </div>
    </ExtantionsContainer>
  )
}
