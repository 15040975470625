import React, { useEffect, useState } from 'react'
import { useData } from '../../context/DataContext'

export default function TabBody({ task }) {
  const [imageError, setImageError] = useState(false)
  const { global } = useData()
  const { settings, styleData, domains, pages, images } = global

  const taskDomain = domains[task.idDomain]
  const taskPage = pages[task.idPages]

  const linkHost = getLinkHost()

  const background =
    task.ownBackground ||
    taskDomain.domainBackground ||
    taskPage.pagesBackground ||
    '#ffffff'

  const color =
    task.ownColor || taskDomain.domainColor || taskPage.pagesColor || '#4285F4'

  const screen = taskPage.pagesPicture || null

  const logo = taskDomain.domainPicture || null

  let image
  if (task.ownPicture && task.ownPicture.startsWith('http')) {
    image = task.ownPicture
  }
  if (task.ownPicture && images[task.ownPicture]) {
    image = images[task.ownPicture].image
  }

  const tabFill = task.tabFill
    ? task.tabFill
    : settings.tabsAsImage
    ? image
      ? 'image'
      : logo
      ? 'logo'
      : screen
      ? 'screen'
      : 'color'
    : 'color'

  function getLinkHost() {
    const domainZones = ['com', 'net', 'org']
    const { domainHostname } = domains[task.idDomain]

    let linkHostLength = domainHostname.length
    let urlParts = domainHostname.split('.')
    let urlPartsLength = domainHostname.split('.')
    let urlShortParts = domainHostname.split('.')

    if (linkHostLength > 18) {
      do {
        let newUrlShortParts = urlShortParts.slice()
        newUrlShortParts.sort(function (a, b) {
          return b.length - a.length
        })
        if (newUrlShortParts[0].length < 5) {
          break
        }
        for (let i = 0; i < urlShortParts.length; i++) {
          if (urlShortParts[i] === newUrlShortParts[0]) {
            urlShortParts[i] =
              urlShortParts[i].slice(0, urlShortParts[i].length - 3) + '..'
          }
        }
      } while (urlShortParts.join('.').length > 18)
    }

    urlPartsLength.sort(function (a, b) {
      return b.length - a.length
    })
    let strong = true
    for (let i = 0; i < urlParts.length; i++) {
      if (
        urlParts[i] === urlPartsLength[0] &&
        i != urlParts.length - 1 &&
        urlParts[i].length > 2 &&
        domainZones.includes(urlParts[i]) === false
      ) {
        urlParts[i] = <b key={'linkHost' + task.id + i}>{urlShortParts[i]}.</b>
        strong = false
      } else {
        if (i != urlParts.length - 1) {
          urlParts[i] = urlShortParts[i] + '.'
        }
      }
    }

    if (strong) {
      urlParts[0] = <b key={'linkHost' + task.id}>{urlParts[0]}</b>
    }

    return urlParts
  }

  const getContent = () => {
    const backgroundStyle = { backgroundColor: background }
    const nameStyle = { color: color, fontSize: `${styleData.tabFontSize}px` }

    const imgFill = (image) => (
      <img
        src={image}
        style={backgroundStyle}
        className="imgLoad"
        onError={() => setImageError(true)}
      />
    )
    const fileFill = () => (
      <span className="backgroundTabs" style={{ backgroundColor: '#ffffff' }}>
        <span
          className="name"
          style={{
            color: '#0364c1',
            fontSize: `${styleData.tabFontSize * 3}px`,
          }}
        >
          <i className="bx bxs-file-import"></i>
        </span>
      </span>
    )
    const bgFill = (classStyle) => (
      <span className={classStyle} style={backgroundStyle}>
        <span className="name" style={nameStyle}>
          {linkHost}
        </span>
      </span>
    )

    switch (tabFill) {
      case 'color':
        return bgFill('backgroundTabs')
      case 'screen':
        if (screen === 'downloadFile') {
          return fileFill()
        }
        return imageError ? bgFill('backgroundTabs') : imgFill(screen)
      case 'logo':
        return imageError ? bgFill('backgroundTabs') : imgFill(logo)
      case 'image':
        return imageError ? bgFill('backgroundTabs') : imgFill(image)
      default:
        return bgFill('background')
    }
  }

  return getContent()
}
