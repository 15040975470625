import React, { useState, useEffect } from 'react'
import { useAuth } from '../context/AuthContext'
import { useData } from '../context/DataContext'
import { database } from '../firebase'

import Today from './components/Today'
import Extantions from './components/Extantions'
import MonthGraph from './components/MonthGraph'
import ExtantionsGraph from './components/ExtantionsGraph'
import { Container } from './styles/GetAnalytics.styled'

export default function GetAnalytics() {
  const { currentUser } = useAuth()
  const { global } = useData()
  const { styleData } = global
  const time = new Date().getTime()
  // const today = new Date(time).toISOString().slice(0, 10)

  const [analytics, setAnalytics] = useState()

  useEffect(() => {
    getAnalytics()
  }, [])

  async function getAnalytics() {
    try {
      const querySnapshot = await database.analytics
        .orderBy('timestamp', 'desc')
        .limit(30)
        .get()

      const analytics = querySnapshot.docs.map((doc) => doc.data())

      // const history = () => getHistory(analytics)

      let newAnalytics = []

      analytics.forEach((item) => {
        const { h_users } = item
        const users = h_users
        // let userIds = []

        let clicks = 0
        let views = 0
        const visitors = Object.keys(users).length

        let history = {}

        let countries = {}
        let languages = {}
        let systems = {}
        let browsers = {}
        let useExtantion = 0
        let notExtantion = 0

        let useRegistration = 0
        let notRegistration = 0

        for (const key in users) {
          if (users.hasOwnProperty(key)) {
            const user = users[key]
            // userIds.push(key)

            clicks += user['click'] || user['0_click'] || 0
            views += user['views'] || user['0_pageViews'] || 0

            history[key] = {
              click: user['click'] || user['0_click'] || 0,
              views: user['views'] || user['0_pageViews'] || 0,
              country: user['country'] || user['b_country'] || 'Unknown',
              city: user['city'] || user['c_city'] || 'Unknown',
              language: user['language'] || user['d_language'] || 'Unknown',
              system: user['system'] || user['e_os'] || 'Unknown',
              browser: user['browser'] || user['f_browser'] || 'Unknown',
              extantion: user['extantion'] || user['h_extantion'] || false,
            }

            //user stats
            const click = user['click'] || user['0_click'] || 0
            const pageViews = user['views'] || user['0_pageViews'] || 0
            const country = user['country'] || user['b_country'] || 'Unknown'
            const city = user['city'] || user['c_city'] || 'Unknown'
            const language = user['language'] || user['d_language'] || 'Unknown'
            const system = user['system'] || user['e_os'] || 'Unknown'
            const browser = user['browser'] || user['f_browser'] || 'Unknown'
            const extantion = user['extantion'] || user['h_extantion'] || false

            if (countries[country]) {
              countries[country].visitors += 1
              countries[country].clicks += click
              countries[country].views += pageViews

              if (countries[country].city[city]) {
                countries[country].city[city].visitors += 1
                countries[country].city[city].clicks += click
                countries[country].city[city].views += pageViews
              } else {
                countries[country].city[city] = {
                  visitors: 1,
                  clicks: click,
                  views: pageViews,
                }
              }
            } else {
              countries[country] = {
                clicks: click,
                views: pageViews,
                visitors: 1,
                city: {
                  [city]: {
                    clicks: click,
                    views: pageViews,
                    visitors: 1,
                  },
                },
              }
            }

            if (languages[language]) {
              languages[language].visitors += 1
              languages[language].clicks += click
              languages[language].views += pageViews
            } else {
              languages[language] = {
                clicks: click,
                views: pageViews,
                visitors: 1,
              }
            }

            if (systems[system]) {
              systems[system].visitors += 1
              systems[system].clicks += click
              systems[system].views += pageViews
            } else {
              systems[system] = {
                clicks: click,
                views: pageViews,
                visitors: 1,
              }
            }

            if (browsers[browser]) {
              browsers[browser].visitors += 1
              browsers[browser].clicks += click
              browsers[browser].views += pageViews
            } else {
              browsers[browser] = {
                clicks: click,
                views: pageViews,
                visitors: 1,
              }
            }

            if (extantion === true || extantion === 'extantion') {
              useExtantion += 1
            } else {
              notExtantion += 1
            }

            if (key.length === 28) {
              useRegistration += 1
            } else {
              notRegistration += 1
            }
          }
        }

        newAnalytics.push({
          timestamp: item.timestamp,
          history,
          clicks,
          views,
          visitors,
          countries,
          languages,
          systems,
          browsers,
          useExtantion,
          notExtantion,
          useRegistration,
          notRegistration,
        })
      })

      console.log(new Date().getTime())

      // let newUsers = []
      // newAnalytics.forEach((item, index) => {
      //   console.log(item.timestamp)
      //   Object.keys(item.history).forEach((user) => {
      //     //find user in history in newAnalytics array from index + 1
      //     for (let i = index + 1; i < newAnalytics.length; i++) {
      //       console.log(newAnalytics[i])
      //       if (newAnalytics[i].history[user]) {
      //         // console.log(user)
      //         // newUsers[item.timestamp] = {
      //         //   ...newUsers[item.timestamp],
      //         //   [user]: {
      //         //     id: user,
      //         //     ...item.history[user],
      //         //   },
      //         // }
      //       }
      //     }
      //   })
      // })
      // console.log(newUsers)

      // console.log(analytics)

      // console.log(newAnalytics)

      setAnalytics(newAnalytics)
    } catch (error) {
      console.error('Error getting analytics:', error)
    }
  }

  return (
    <Container styleData={styleData}>
      {analytics ? (
        <>
          <Today state={analytics[0]} />
          <Extantions state={analytics[0]} />
          <MonthGraph state={analytics} />
          <ExtantionsGraph state={analytics} />
        </>
      ) : null}
    </Container>
  )
}
