import React from 'react'
import { TodayContainer } from '../styles/GetAnalytics.styled'

export default function Today({ state }) {
  return (
    <TodayContainer>
      <div className="content">
        <div className="visitors">
          <div className="number">{state.visitors}</div>
          <div className="title">Visitors</div>
          <hr />
          <div className="number-su">{state.visitors}</div>
          <div className="title-su">New visitors</div>
        </div>
        <div className="views">
          <div className="number">{state.views}</div>
          <div className="title">Views</div>
          <hr />
          <div className="number-sub">
            {(state.views / state.visitors).toFixed(1)}
          </div>
          <div className="title-sub">Views per visitor</div>
        </div>
        <div className="clicks">
          <div className="number">{state.clicks}</div>
          <div className="title">Clicks</div>
          <hr />
          <div className="number-su">
            {(state.clicks / state.visitors).toFixed(1)}
          </div>
          <div className="title-su">Clicks per visitor</div>
        </div>
      </div>
    </TodayContainer>
  )
}
